// src/app/global-error.tsx
'use client';
import { useEffect } from 'react';

export default function GlobalError({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    console.error('Global Error:', error);
  }, [error]);

  return (
    <html>
      <body>
        <h2>Une erreur est survenue !</h2>
        <p>contacter le supprt</p>
        <p>{error.message}</p>
        {error.digest && <p>Erreur de diagnostic : {error.digest}</p>}
        <button onClick={() => reset()}>Réessayer</button>
      </body>
    </html>
  );
}
